import { Box, Button, Typography, useTheme, Container, Grid, Card, CardContent, Fade, Slide, IconButton, Divider, Avatar } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { styled } from "@mui/system";
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const IOS_STORE_URL = "https://apps.apple.com/app/apple-store/id6446214998?pt=126080231&ct=landing-page&mt=8";

// Styled components
const GradientButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  color: theme.palette.neutral.main,
  fontWeight: 600,
  padding: "12px 24px",
  borderRadius: "30px",
  textTransform: "none",
  fontSize: "1rem",
  boxShadow: "0 4px 20px rgba(123, 0, 255, 0.25)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    transform: "translateY(-3px)",
    boxShadow: "0 6px 25px rgba(123, 0, 255, 0.35)",
  },
}));

const PhoneFrame = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "280px",
  height: "560px",
  borderRadius: "36px",
  padding: "12px",
  background: "#111",
  boxShadow: "0 30px 60px rgba(0,0,0,0.4)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  margin: "0 auto",
}));

const PhoneScreen = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "24px",
  overflow: "hidden",
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: "100%",
  borderRadius: "16px",
  background: "rgba(40, 40, 40, 0.8)",
  backdropFilter: "blur(10px)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  border: "1px solid rgba(123, 0, 255, 0.3)",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 30px rgba(123, 0, 255, 0.4)",
    border: "1px solid rgba(123, 0, 255, 0.6)",
  },
}));

const ScrollDownButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  background: "rgba(40, 40, 40, 0.6)",
  color: theme.palette.neutral.main,
  "&:hover": {
    background: "rgba(40, 40, 40, 0.8)",
  },
  animation: "bounce 2s infinite",
  "@keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": {
      transform: "translateY(0) translateX(-50%)",
    },
    "40%": {
      transform: "translateY(-10px) translateX(-50%)",
    },
    "60%": {
      transform: "translateY(-5px) translateX(-50%)",
    },
  },
}));

const FaqAccordion = styled(Box)(({ theme, expanded }) => ({
  borderRadius: "12px",
  padding: "16px",
  marginBottom: "16px",
  background: expanded ? "rgba(123, 0, 255, 0.2)" : "rgba(40, 40, 40, 0.6)",
  backdropFilter: "blur(10px)",
  transition: "all 0.3s ease",
  cursor: "pointer",
  border: expanded ? "1px solid rgba(123, 0, 255, 0.4)" : "1px solid rgba(255, 255, 255, 0.15)",
  "&:hover": {
    background: expanded ? "rgba(123, 0, 255, 0.25)" : "rgba(40, 40, 40, 0.8)",
  },
}));

// Party-themed background elements
const PartyElement = styled(Box)(({ theme, type }) => {
  // Base styles for all party elements
  const baseStyles = {
    position: "fixed",
    zIndex: 0,
    pointerEvents: "none",
  };
  
  // Specific styles based on element type
  switch(type) {
    case 'confetti':
      return {
        ...baseStyles,
        width: "15px",
        height: "15px",
        background: (props) => props.color || theme.palette.primary.main,
        opacity: 0.7,
        borderRadius: "2px",
        transform: "rotate(45deg)",
        animation: "confettiFall 10s linear infinite",
      };
    case 'lightBeam':
      return {
        ...baseStyles,
        width: "150px",
        height: "800px",
        background: (props) => `linear-gradient(to bottom, transparent, ${props.color || theme.palette.primary.main}80, transparent)`,
        opacity: 0.3,
        transform: "rotate(45deg)",
        transformOrigin: "top center",
        animation: "lightBeamSweep 12s ease-in-out infinite",
        filter: "blur(20px)",
      };
    case 'soundWave':
      return {
        ...baseStyles,
        width: "300px",
        height: "300px",
        borderRadius: "50%",
        border: (props) => `2px solid ${props.color || theme.palette.primary.main}40`,
        opacity: 0.4,
        animation: "soundWavePulse 4s ease-out infinite",
      };
    default:
      return baseStyles;
  }
});

// Add new styled components for the hero section
const AnimatedText = styled(Typography)(({ theme, delay = 0 }) => ({
  opacity: 0,
  animation: `fadeSlideIn 0.8s ${delay}s forwards`,
  "@keyframes fadeSlideIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
}));

const RotatingText = styled(Box)(({ theme }) => ({
  height: "1.5em",
  overflow: "hidden",
  position: "relative",
  "& span": {
    display: "block",
    height: "100%",
    animation: "spin 16s infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "translateY(0%)",
    },
    "16.66%": {
      transform: "translateY(0%)",
    },
    "20%": {
      transform: "translateY(-100%)",
    },
    "36.66%": {
      transform: "translateY(-100%)",
    },
    "40%": {
      transform: "translateY(-200%)",
    },
    "56.66%": {
      transform: "translateY(-200%)",
    },
    "60%": {
      transform: "translateY(-300%)",
    },
    "76.66%": {
      transform: "translateY(-300%)",
    },
    "80%": {
      transform: "translateY(-400%)",
    },
    "96.66%": {
      transform: "translateY(-400%)",
    },
    "100%": {
      transform: "translateY(0%)",
    }
  },
}));

const LandingPage = () => {
  const { palette } = useTheme();
  const [activeScreen, setActiveScreen] = useState(0);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [heroAnimationComplete, setHeroAnimationComplete] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const heroRef = useRef(null);
  
  // Party background colors
  const partyColors = [
    "#7b00ff", // Purple
    "#ff3e6f", // Pink
    "#00d4ff", // Cyan
    "#ffcc00", // Yellow
    "#ff9500", // Orange
    "#00ff88", // Green
  ];

  const screens = [
    "/assets/homeScreen.png",
    "/assets/barScreen.png",
    "/assets/postScreen.png"
  ];

  // Re-enable auto rotate screens for the "Your Night Out, Amplified!" section
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveScreen((prev) => (prev + 1) % screens.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [screens.length]);

  // Track scroll position for animations
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Track mouse position for parallax effect
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (heroRef.current) {
        const rect = heroRef.current.getBoundingClientRect();
        setMousePosition({
          x: (e.clientX - rect.left) / rect.width,
          y: (e.clientY - rect.top) / rect.height,
        });
      }
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);
  
  // Trigger hero animation completion
  useEffect(() => {
    const timer = setTimeout(() => {
      setHeroAnimationComplete(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  // Add privacy policy to the faqs array
  const faqs = [
    {
      question: "How do I download the DoorGuy app? 📱",
      answer: "You can download the DoorGuy app from the iOS App Store. Just search for 'DoorGuy' and tap install!"
    },
    {
      question: "How do I report a problem or provide feedback? 💬",
      answer: "You can contact us at thedoorguys@doorguyapp.com for any questions, problems, or feedback. We're always looking to improve!"
    },
    {
      question: "Is my personal information secure? 🔒",
      answer: "Yes, we take the security of your personal information seriously and use industry-standard encryption to protect it. Please refer to our Privacy Policy for more details."
    },
    {
      question: "How do I find bars near me using the DoorGuy app? 🔍",
      answer: "Simply open the app, and it will automatically display a list of bars in Columbia, Missouri with real-time information about wait times, cover charges, and more!"
    },
    {
      question: "What is your Privacy Policy? 🔐",
      answer: `Thank you for choosing to use our app. Protecting your privacy and personal information is a top priority for us. 
      
      Information We Collect: When you use our app, we may collect certain information from you, including your location. However, we do not store this information on our servers or share it with third parties. We only use your location to show you relevant information about bars in your area. We do not collect or store any other personally identifiable information from you.
      
      Use of Information: We use the information we collect from you to provide you with a better experience when using our app. Specifically, we use your location to show you relevant information about bars in your area. We do not use this information for any other purpose or share it with third parties.
      
      Security of Information: We take the security of your information seriously and take appropriate measures to protect it. We do not store your location information on our servers or share it with third parties. We also use industry-standard encryption to protect any information that is transmitted between our app and our servers.
      
      Changes to this Privacy Policy: We reserve the right to update or modify this privacy policy at any time. If we make any material changes to this policy, we will notify you by posting the new policy on our website or within the app itself.`
    }
  ];

  return (
    <Box sx={{
      color: palette.neutral.light,
      background: `linear-gradient(135deg, #121212 0%, #2A2A2A 100%)`,
      minHeight: "100vh",
      overflowX: "hidden",
      position: "relative"
    }}>
      {/* Background elements */}
      <Box sx={{ 
        position: "fixed", 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        pointerEvents: "none",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "radial-gradient(circle at center, rgba(0,0,0,0) 0%, #121212 100%)",
          zIndex: 1,
        },
        "& > *": {
          zIndex: 0,
        },
        "@keyframes confettiFall": {
          "0%": {
            transform: "translateY(-100px) rotate(0deg)",
            opacity: 0,
          },
          "10%": {
            opacity: 0.7,
          },
          "100%": {
            transform: "translateY(100vh) rotate(360deg)",
            opacity: 0,
          }
        },
        "@keyframes lightBeamSweep": {
          "0%": {
            transform: "rotate(25deg)",
            opacity: 0.1,
          },
          "50%": {
            transform: "rotate(65deg)",
            opacity: 0.3,
          },
          "100%": {
            transform: "rotate(25deg)",
            opacity: 0.1,
          }
        },
        "@keyframes soundWavePulse": {
          "0%": {
            transform: "scale(0.1)",
            opacity: 0.8,
          },
          "100%": {
            transform: "scale(3)",
            opacity: 0,
          }
        },
        "@keyframes neon": {
          "0%": {
            filter: "brightness(1) blur(20px)",
          },
          "50%": {
            filter: "brightness(1.3) blur(15px)",
          },
          "100%": {
            filter: "brightness(1) blur(20px)",
          }
        }
      }}>
        {/* Enhanced light beams with neon effect - with parallax effect */}
        <PartyElement type="lightBeam" color={partyColors[0]} sx={{ 
          top: `-400px`, 
          left: `${10 + scrollPosition * 0.02}%`, 
          animationDelay: "0s", 
          opacity: 0.6,
          animation: "lightBeamSweep 12s ease-in-out infinite, neon 4s infinite",
          filter: "blur(15px)",
          transform: `rotate(${45 + scrollPosition * 0.01}deg)`,
          transition: "transform 0.1s ease-out",
        }} />
        <PartyElement type="lightBeam" color={partyColors[1]} sx={{ 
          top: `-400px`, 
          left: `${30 - scrollPosition * 0.01}%`, 
          animationDelay: "2s", 
          opacity: 0.6,
          animation: "lightBeamSweep 12s ease-in-out infinite, neon 4s infinite 1s",
          filter: "blur(15px)",
          transform: `rotate(${45 - scrollPosition * 0.01}deg)`,
          transition: "transform 0.1s ease-out",
        }} />
        <PartyElement type="lightBeam" color={partyColors[2]} sx={{ 
          top: `-400px`, 
          left: `${50 + scrollPosition * 0.015}%`, 
          animationDelay: "4s", 
          opacity: 0.6,
          animation: "lightBeamSweep 12s ease-in-out infinite, neon 4s infinite 2s",
          filter: "blur(15px)",
          transform: `rotate(${45 + scrollPosition * 0.008}deg)`,
          transition: "transform 0.1s ease-out",
        }} />
        <PartyElement type="lightBeam" color={partyColors[3]} sx={{ 
          top: `-400px`, 
          left: `${70 - scrollPosition * 0.01}%`, 
          animationDelay: "6s", 
          opacity: 0.6,
          animation: "lightBeamSweep 12s ease-in-out infinite, neon 4s infinite 3s",
          filter: "blur(15px)",
          transform: `rotate(${45 - scrollPosition * 0.012}deg)`,
          transition: "transform 0.1s ease-out",
        }} />
        <PartyElement type="lightBeam" color={partyColors[4]} sx={{ 
          top: `-400px`, 
          left: `${90 + scrollPosition * 0.01}%`, 
          animationDelay: "8s", 
          opacity: 0.6,
          animation: "lightBeamSweep 12s ease-in-out infinite, neon 4s infinite 4s",
          filter: "blur(15px)",
          transform: `rotate(${45 + scrollPosition * 0.01}deg)`,
          transition: "transform 0.1s ease-out",
        }} />
        
        {/* Confetti effect when download button is clicked */}
        {showConfetti && partyColors.map((color, index) => (
          <PartyElement 
            key={`download-confetti-${index}`} 
            type="confetti" 
            color={color}
            sx={{ 
              top: "-20px", 
              left: `${(index + 1) * 15}%`, 
              animationDelay: `${index * 0.1}s`,
              animationDuration: `${2 + Math.random() * 2}s`,
              opacity: 0.9,
            }} 
          />
        ))}
        {showConfetti && partyColors.map((color, index) => (
          <PartyElement 
            key={`download-confetti2-${index}`} 
            type="confetti" 
            color={color}
            sx={{ 
              top: "-20px", 
              left: `${(index + 1) * 15 + 7}%`, 
              animationDelay: `${index * 0.1 + 0.2}s`,
              animationDuration: `${2 + Math.random() * 2}s`,
              opacity: 0.9,
            }} 
          />
        ))}
      </Box>

      {/* Enhanced Hero Section */}
      <Box
        ref={heroRef}
        sx={{
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          zIndex: 2,
          padding: { xs: "20px", md: "40px" },
          backgroundImage: "radial-gradient(circle at 50% 50%, rgba(123, 0, 255, 0.3) 0%, rgba(0, 0, 0, 0) 70%)",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `url('/assets/noise.png')`,
            opacity: 0.05,
            zIndex: -1,
            pointerEvents: "none",
          },
        }}
        id="hero"
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                <AnimatedText
                  fontWeight="bold"
                  fontSize={{ xs: "48px", md: "72px" }}
                  color="primary"
                  sx={{
                    mb: 1,
                    background: "linear-gradient(90deg, #7b00ff 0%, #D5B3FD 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    textShadow: "0 0 30px rgba(123, 0, 255, 0.5)",
                    position: "relative",
                    display: "inline-block",
                    "&::after": {
                      content: 'none',
                    },
                  }}
                >
                  DoorGuy
                </AnimatedText>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start" }, mb: 3 }}>
                  <AnimatedText
                    variant="h3"
                    delay={0.2}
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "1.8rem", md: "2.8rem" },
                      color: "#ffffff",
                      textShadow: "0 2px 10px rgba(0,0,0,0.5)",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    Know before
                  </AnimatedText>
                </Box>
                
                <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                  <AnimatedText
                    variant="h3"
                    delay={0.3}
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "1.8rem", md: "2.8rem" },
                      color: "#ffffff",
                      textShadow: "0 2px 10px rgba(0,0,0,0.5)",
                      mr: 2,
                    }}
                  >
                    you
                  </AnimatedText>
                  
                  <AnimatedText
                    variant="h3"
                    delay={0.4}
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "1.8rem", md: "2.8rem" },
                      color: "#ffffff",
                      textShadow: "0 2px 10px rgba(0,0,0,0.5)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <RotatingText>
                      <span style={{ display: "flex", alignItems: "center" }}>go! 🎟️</span>
                      <span style={{ display: "flex", alignItems: "center" }}>pay! 🍸</span>
                      <span style={{ display: "flex", alignItems: "center" }}>party! 🎉</span>
                      <span style={{ display: "flex", alignItems: "center" }}>dance! 💃</span>
                      <span style={{ display: "flex", alignItems: "center" }}>vibe! 😎</span>
                    </RotatingText>
                  </AnimatedText>
                </Box>

                <AnimatedText
                  variant="h5"
                  delay={0.6}
                  sx={{
                    mb: 5,
                    maxWidth: "600px",
                    mx: { xs: "auto", md: 0 },
                    color: "#ffffff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  The perfect bar crawl is just a tap away. Real-time updates on wait times, cover charges, and vibes. Let's get this party started!
                </AnimatedText>

                <AnimatedText delay={0.7} sx={{ 
                  textAlign: { xs: "center", md: "left" },
                  opacity: heroAnimationComplete ? 1 : 0.9,
                  transition: "opacity 0.3s ease"
                }}>
                  <GradientButton
                    startIcon={<AppleIcon />}
                    size="large"
                    onClick={() => {
                      setShowConfetti(true);
                      setTimeout(() => setShowConfetti(false), 3000);
                      window.open(IOS_STORE_URL, "_blank");
                    }}
                    sx={{ 
                      fontSize: "1.2rem", 
                      py: 1.5, 
                      px: 4,
                      position: "relative",
                      overflow: "hidden",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        top: "-50%",
                        left: "-50%",
                        width: "200%",
                        height: "200%",
                        background: "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)",
                        transform: "rotate(30deg)",
                        transition: "all 0.6s ease",
                        opacity: 0,
                      },
                      "&:hover::after": {
                        opacity: 1,
                        left: "100%",
                      }
                    }}
                  >
                    Download now
                  </GradientButton>
                </AnimatedText>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
              <Slide direction="left" in={true} timeout={1000}>
                <Box sx={{ 
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    width: "150%",
                    height: "150%",
                    top: "-25%",
                    left: "-25%",
                    background: "radial-gradient(circle, rgba(123, 0, 255, 0.2) 0%, rgba(0, 0, 0, 0) 70%)",
                    zIndex: -1,
                    animation: "pulse 4s infinite",
                  }
                }}>
                  <PhoneFrame sx={{ 
                    transform: `perspective(1000px) rotateY(${mousePosition.x * 10 - 5}deg) rotateX(${mousePosition.y * -10 + 5}deg)`,
                    transition: "transform 0.1s ease-out",
                    boxShadow: "0 30px 60px rgba(0,0,0,0.4), 0 0 30px rgba(123, 0, 255, 0.3)",
                  }}>
                    <PhoneScreen>
                      {/* Only show homeScreen.png */}
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <img
                          src="/assets/homeScreen.png"
                          alt="App Screenshot"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '24px'
                          }}
                        />
                      </Box>
                    </PhoneScreen>
                  </PhoneFrame>
                </Box>
              </Slide>
            </Grid>
          </Grid>
        </Container>

        <ScrollDownButton 
          onClick={() => scrollToSection("features")}
          sx={{
            background: "rgba(123, 0, 255, 0.3)",
            "&:hover": {
              background: "rgba(123, 0, 255, 0.5)",
            },
          }}
        >
          <ExpandMoreIcon />
        </ScrollDownButton>
      </Box>

      {/* App Showcase Section */}
      <Container maxWidth="lg" id="features" sx={{ py: 10, position: "relative", zIndex: 2 }}>
        <Fade in={true} timeout={1000}>
          <Typography
            variant="h2"
            sx={{
              mb: 6,
              textAlign: "center",
              fontWeight: 700,
              background: "linear-gradient(90deg, #7b00ff 0%, #D5B3FD 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textShadow: "0 0 20px rgba(123, 0, 255, 0.3)",
              position: "relative",
              display: "inline-block",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            Your Night Out, Amplified!
          </Typography>
        </Fade>

        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <Slide direction="right" in={true} timeout={1000}>
              <Box sx={{ position: "relative" }}>
                <PhoneFrame>
                  <PhoneScreen>
                    {screens.map((screen, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          opacity: activeScreen === index ? 1 : 0,
                          transition: 'opacity 0.8s ease-in-out',
                          zIndex: activeScreen === index ? 2 : 1
                        }}
                      >
                        <img
                          src={screen}
                          alt={`App Screenshot ${index + 1}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '24px'
                          }}
                        />
                      </Box>
                    ))}
                  </PhoneScreen>
                </PhoneFrame>
              </Box>
            </Slide>
          </Grid>

          <Grid item xs={12} md={6}>
            <Slide direction="left" in={true} timeout={1000}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FeatureCard>
                      <CardContent sx={{ color: "#ffffff" }}>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          <Avatar sx={{ bgcolor: palette.primary.main, mr: 2 }}>
                            <LocationOnIcon />
                          </Avatar>
                          <Typography variant="h5" fontWeight={600}>Real-Time Venue Updates</Typography>
                        </Box>
                        <Typography variant="body1" sx={{ color: "#ffffff" }}>
                          Stay informed with live updates on wait times, cover charges, and crowd levels at your favorite venues.
                        </Typography>
                      </CardContent>
                    </FeatureCard>
                  </Grid>

                  <Grid item xs={12}>
                    <FeatureCard>
                      <CardContent sx={{ color: "#ffffff" }}>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          <Avatar sx={{ bgcolor: palette.primary.main, mr: 2 }}>
                            <PhoneIphoneIcon />
                          </Avatar>
                          <Typography variant="h5" fontWeight={600}>Intuitive Interface</Typography>
                        </Box>
                        <Typography variant="body1" sx={{ color: "#ffffff" }}>
                          Easily navigate venue information with a clean, user-friendly design optimized for quick access on the go.
                        </Typography>
                      </CardContent>
                    </FeatureCard>
                  </Grid>

                  <Grid item xs={12}>
                    <FeatureCard>
                      <CardContent sx={{ color: "#ffffff" }}>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          <Avatar sx={{ bgcolor: palette.primary.main, mr: 2 }}>
                            <QuestionAnswerIcon />
                          </Avatar>
                          <Typography variant="h5" fontWeight={600}>Crowd Insights</Typography>
                        </Box>
                        <Typography variant="body1" sx={{ color: "#ffffff" }}>
                          Check real-time feedback from other users and share your own experiences to help others find the best spots.
                        </Typography>
                      </CardContent>
                    </FeatureCard>
                  </Grid>
                </Grid>
              </Box>
            </Slide>
          </Grid>
        </Grid>
      </Container>

      {/* FAQ Section */}
      <Box
        sx={{
          py: 10,
          background: "linear-gradient(180deg, rgba(40, 40, 40, 0) 0%, rgba(40, 40, 40, 0.8) 100%)",
          position: "relative",
          zIndex: 2,
        }}
        id="faq"
      >
        <Container maxWidth="md">
          <Fade in={true} timeout={1000}>
            <Typography
              variant="h2"
              sx={{
                mb: 6,
                textAlign: "center",
                fontWeight: 600,
                background: "linear-gradient(90deg, #7b00ff 0%, #D5B3FD 100%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textShadow: "0 0 20px rgba(123, 0, 255, 0.3)",
              }}
            >
              Party FAQs
            </Typography>
          </Fade>

          {faqs.map((faq, index) => (
            <Fade key={index} in={true} timeout={1000} style={{ transitionDelay: `${index * 100}ms` }}>
              <FaqAccordion expanded={expandedFaq === index} onClick={() => toggleFaq(index)}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="h5" fontWeight={600} color="#ffffff">
                    {faq.question}
                  </Typography>
                  <ExpandMoreIcon
                    sx={{
                      transform: expandedFaq === index ? 'rotate(180deg)' : 'rotate(0)',
                      transition: 'transform 0.3s ease',
                      color: "#ffffff"
                    }}
                  />
                </Box>

                {expandedFaq === index && (
                  <Fade in={expandedFaq === index} timeout={500}>
                    <Box>
                      <Divider sx={{ my: 2, bgcolor: 'rgba(255,255,255,0.2)' }} />
                      <Typography variant="body1" color="#ffffff" sx={{ whiteSpace: 'pre-line' }}>
                        {faq.answer}
                      </Typography>
                    </Box>
                  </Fade>
                )}
              </FaqAccordion>
            </Fade>
          ))}
        </Container>
      </Box>

      {/* Contact Section */}
      <Box
        sx={{
          py: 8,
          background: `linear-gradient(135deg, rgba(123, 0, 255, 0.25) 0%, rgba(40, 40, 40, 0.6) 100%)`,
          backdropFilter: "blur(10px)",
          position: "relative",
          zIndex: 2,
        }}
        id="contact"
      >
        <Container maxWidth="md">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Fade in={true} timeout={1000}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      mb: 3,
                      fontWeight: 600,
                      background: "linear-gradient(90deg, #7b00ff 0%, #D5B3FD 100%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textShadow: "0 0 20px rgba(123, 0, 255, 0.3)",
                    }}
                  >
                    Let's Connect!
                  </Typography>

                  <Typography variant="body1" sx={{ mb: 4, color: "#ffffff" }}>
                    Have questions, feedback, or just want to share your epic night stories? We'd love to hear from you!
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <EmailIcon sx={{ mr: 2, color: palette.primary.main }} />
                    <Typography variant="body1">
                      <a
                        href="mailto:thedoorguys@doorguyapp.com"
                        style={{
                          color: "#ffffff",
                          textDecoration: "none",
                          borderBottom: `1px solid ${palette.primary.main}`,
                          paddingBottom: "2px",
                          transition: "color 0.3s ease",
                        }}
                        onMouseOver={(e) => e.target.style.color = palette.primary.main}
                        onMouseOut={(e) => e.target.style.color = "#ffffff"}
                      >
                        thedoorguys@doorguyapp.com
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Fade>
            </Grid>

            <Grid item xs={12} md={6}>
              <Fade in={true} timeout={1000}>
                <Box sx={{ textAlign: "center", position: "relative" }}>
                  <GradientButton
                    startIcon={<AppleIcon />}
                    size="large"
                    sx={{ 
                      mb: 3,
                      fontSize: "1.2rem",
                      py: 1.5,
                      px: 4,
                    }}
                    onClick={() => {
                      setShowConfetti(true);
                      setTimeout(() => setShowConfetti(false), 3000);
                      window.open(IOS_STORE_URL, "_blank");
                    }}
                  >
                    Download now
                  </GradientButton>

                  <Typography variant="body2" sx={{ color: "#ffffff" }}>
                    Available now for iOS devices - Your ticket to the best nights out! 🎟️
                  </Typography>
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          py: 4,
          background: "rgba(40, 40, 40, 0.9)",
          borderTop: `1px solid rgba(255,255,255,0.1)`,
          position: "relative",
          zIndex: 2,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                fontWeight="bold"
                fontSize="24px"
                color="primary"
                sx={{
                  background: "linear-gradient(90deg, #7b00ff 0%, #D5B3FD 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                DoorGuy
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "flex-end" }, gap: 3 }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                    cursor: "pointer",
                    "&:hover": { color: palette.primary.main }
                  }}
                  onClick={() => scrollToSection("hero")}
                >
                  Home
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                    cursor: "pointer",
                    "&:hover": { color: palette.primary.main }
                  }}
                  onClick={() => scrollToSection("features")}
                >
                  Features
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                    cursor: "pointer",
                    "&:hover": { color: palette.primary.main }
                  }}
                  onClick={() => scrollToSection("faq")}
                >
                  FAQ
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                    cursor: "pointer",
                    "&:hover": { color: palette.primary.main }
                  }}
                  onClick={() => scrollToSection("contact")}
                >
                  Contact
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ color: "#c0c0c0", textAlign: "center", mt: 2 }}>
                © {new Date().getFullYear()} DoorGuy. All rights reserved. Let's party responsibly!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;